import { Injectable, Injector } from '@angular/core';
import { getToken, isSupported, Messaging } from '@angular/fire/messaging';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { environment } from 'apps/hospital-admin/src/environments/environment';
import { EMPTY, filter, from, fromEvent, map, of, switchMap, withLatestFrom } from 'rxjs';

import { UpdateNotificationsPermissionsMessage } from './firebase.messages';
import { FirebaseService } from './firebase.service';
import {
  CheckForNotificationsPermissionSignal,
  RequestNotificationsPermissionSignal,
  SetServiceWorkerSignal,
  SubscribeFireBaseTokenToBackendSignal,
} from './firebase.signals';

@Injectable()
export class FirebaseEffects {
  private messaging!: Messaging;

  requestNotificationsPermissionSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestNotificationsPermissionSignal>(RequestNotificationsPermissionSignal.TYPE),
      switchMap(() => from(Notification.requestPermission())),
      map((permission) => new UpdateNotificationsPermissionsMessage({ permission })),
    ),
  );

  notificationsPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckForNotificationsPermissionSignal>(CheckForNotificationsPermissionSignal.TYPE),
      switchMap(() => from(isSupported())),
      filter((supported) => !!supported),
      map(() => new UpdateNotificationsPermissionsMessage({ permission: Notification.permission })),
    ),
  );

  setServiceWorkerIfGrantedPermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateNotificationsPermissionsMessage>(UpdateNotificationsPermissionsMessage.TYPE),
      filter((action) => action.payload.permission === 'granted'),
      map(() => new SetServiceWorkerSignal({})),
    ),
  );

  setServiceWorkerAndGetToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetServiceWorkerSignal>(SetServiceWorkerSignal.TYPE),
      // eslint-disable-next-line @ngrx/prefer-concat-latest-from
      withLatestFrom(from(isSupported())),
      filter(([, supported]) => !!supported),
      switchMap(() => {
        const serviceWorkerConfig = 'firebase-messaging-sw-config.js?v=1';

        this.messaging = this.injector.get(Messaging);
        return from(
          navigator.serviceWorker.register(serviceWorkerConfig, {
            type: 'module',
            scope: '__',
          }),
        );
      }),
      switchMap((serviceWorkerRegistration) => {
        let serviceWorker: ServiceWorker | undefined;

        if (serviceWorkerRegistration.installing)
          serviceWorker = serviceWorkerRegistration.installing;
        else if (serviceWorkerRegistration.waiting)
          serviceWorker = serviceWorkerRegistration.waiting;
        else if (serviceWorkerRegistration.active) serviceWorker = serviceWorkerRegistration.active;
        else return EMPTY;
        serviceWorkerRegistration.update();
        if (serviceWorker.state === 'activated') return of(serviceWorkerRegistration);

        return fromEvent(serviceWorker, 'statechange').pipe(
          filter((r) => (r.target as ServiceWorker).state === 'activated'),
          map(() => serviceWorkerRegistration),
        );
      }),
      filter((serviceWorkerRegistration) => !!serviceWorkerRegistration),
      switchMap((serviceWorkerRegistration) =>
        from(
          getToken(this.messaging, {
            serviceWorkerRegistration,
            vapidKey: environment.firebase.vapidKey,
          }),
        ).pipe(map((token) => new SubscribeFireBaseTokenToBackendSignal({ token }))),
      ),
    ),
  );

  subscribeFireBaseTokenToBackendSignal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<SubscribeFireBaseTokenToBackendSignal>(SubscribeFireBaseTokenToBackendSignal.TYPE),
        switchMap(({ payload }) => this.firebaseService.addToken(payload.token)),
      ),
    {
      dispatch: false,
    },
  );

  constructor(
    private injector: Injector,
    private actions$: Actions,
    private firebaseService: FirebaseService,
  ) {}
}
