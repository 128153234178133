import { inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { FirebasePersistanceService } from './firebase.persistence.service';
import { selectNotificationsPermission } from './firebase.selectors';

@Injectable({ providedIn: 'root' })
export class FirebaseService {
  private persistanceService = inject(FirebasePersistanceService);
  private store = inject(Store);

  addToken(token: string) {
    return this.persistanceService.addToken(token);
  }

  getNotificationsPermission() {
    return this.store.pipe(select(selectNotificationsPermission));
  }
}
