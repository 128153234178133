import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { FirebaseEffects } from './+state/firebase.effects';
import { featureKey, reducer } from './+state/firebase.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([FirebaseEffects]),
    StoreModule.forFeature(featureKey, reducer),
  ],
})
export class FirebaseModule {}
