import { createFeatureSelector, createSelector } from '@ngrx/store';

import { featureKey } from './firebase.reducer';
import { State } from './interfaces';

const selectFirebaseState = createFeatureSelector<State>(featureKey);

export const selectNotificationsPermission = createSelector(
  selectFirebaseState,
  (state) => state.notificationsPermission
);
