import { signalableFactory } from './firebase.adapter';

export class SetServiceWorkerSignal extends signalableFactory.create<
  'Set Service Worker Signal',
  Record<string, never>
>('Set Service Worker Signal') {}

export class SubscribeFireBaseTokenToBackendSignal extends signalableFactory.create<
  'Subscribe FireBase Token To Backend Signal',
  { token: string }
>('Subscribe FireBase Token To Backend Signal') {}

export class CheckForNotificationsPermissionSignal extends signalableFactory.create<
  'Check For Notifications Permission Signal',
  Record<string, never>
>('Check For Notifications Permission Signal') {}

export class RequestNotificationsPermissionSignal extends signalableFactory.create<
  'Request Notifications Permission Signal',
  Record<string, never>
>('Request Notifications Permission Signal') {}
