import { Injectable } from '@angular/core';
import { LocumsNestEndpointConfig } from 'apps/hospital-admin/src/app/core/constants';
import { catchError, EMPTY } from 'rxjs';

import { HttpApiPersistenceService } from '@locumsnest/core/src';

@Injectable({ providedIn: 'root' })
export class FirebasePersistanceService extends HttpApiPersistenceService<
  LocumsNestEndpointConfig,
  {}
> {
  override readonly endpoint = 'pushNotificationAddToken';

  addToken(token: string) {
    return this.create({ token }).pipe(
      catchError((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        return EMPTY;
      }),
    );
  }
}
