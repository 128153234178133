import { FirebaseMessages, UpdateNotificationsPermissionsMessage } from './firebase.messages';
import { State } from './interfaces';

export const featureKey = 'firebase';

export const INITIAL_STATE: State = {
  notificationsPermission: 'default',
};

export function reducer(state = INITIAL_STATE, action: FirebaseMessages) {
  switch (action.type) {
    case UpdateNotificationsPermissionsMessage.TYPE: {
      const { payload } = action as UpdateNotificationsPermissionsMessage;
      state = {
        ...state,
        notificationsPermission: payload.permission,
      };
      break;
    }
    default:
      break;
  }

  return state;
}
